import React from 'react';
import BusinessSupportImage from '../../assets/service1.jpg'; // Add service images in the assets folder
import ProspectingToolsImage from '../../assets/service2.jpg';
import DataAnalyticsImage from '../../assets/service3.jpg';
import LeadGenerationImage from '../../assets/service4.jpg';
import { ServiceCard, ServiceImage, ServicesContainer, ServicesGrid, ServiceTitle, Title, FlipCardInner, FlipCardFront, FlipCardBack, ServiceDescription } from './styles';
import Button from '../Button';
import useLocation from '../../hooks/useLocation';

interface ServicesProps {
  onContactClick: () => void;
}


const AllServices: React.FC<ServicesProps> = ({ onContactClick }) => {
  const { showLeadGen } = useLocation();

  return (
    <ServicesContainer>
      <Title>Our Services</Title>
      <ServicesGrid>
        {showLeadGen && (
          <ServiceCard>
            <FlipCardInner className="flip-card-inner">
              <FlipCardFront>
                <ServiceTitle>Lead Generation</ServiceTitle>
                <ServiceImage src={LeadGenerationImage} alt="Lead Generation" />
                <ServiceDescription>
                  <li>Targeted Lead Lists</li>
                  <li>Quality Leads</li>
                  <li>Email Deliverability</li>
                </ServiceDescription>
              </FlipCardFront>
              <FlipCardBack>
                <ServiceTitle>Lead Generation</ServiceTitle>
                <ServiceDescription>
                  <li><strong>Targeted Lead Lists:</strong> Customized lead lists tailored to your specific target audience, 
                  ensuring higher relevance and engagement.</li>
                  <li><strong>Quality Leads:</strong> We specialize in identifying and engaging high-potential prospects, 
                  ensuring a steady flow of qualified leads to your sales team.</li>
                  <li><strong>Email Deliverability:</strong> Enhance Deliverability with SPF, DKIM, and DMARC Support.</li>
                </ServiceDescription>
                <Button onClick={onContactClick}>Contact Us</Button>
              </FlipCardBack>
            </FlipCardInner>
          </ServiceCard>
        )}
        <ServiceCard>
          <FlipCardInner className="flip-card-inner">
            <FlipCardFront>
              <ServiceTitle>Prospecting Tools</ServiceTitle>
              <ServiceImage src={ProspectingToolsImage} alt="Prospecting Tools" />
              <ServiceDescription>
                <li>Email Permutator</li>
                <li>Email Validation</li>
                <li>Email Finder</li>
              </ServiceDescription>
            </FlipCardFront>
            <FlipCardBack>
              <ServiceTitle>Prospecting Tools</ServiceTitle>
              <ServiceDescription>
                <li><strong>Email Permutator:</strong> Combining permutation tools with domain patterns we help you to find that valid email you`re looking for.</li>
                <li><strong>Email Validation:</strong> Our validation tools help you avoid invalid emails that might compromise your deliverability.</li>
                <li><strong>Email Finder:</strong> Our email finder allows you to find the prospect and the emails you're looking for.</li>
              </ServiceDescription>
            </FlipCardBack>
          </FlipCardInner>
        </ServiceCard>
        <ServiceCard>
          <FlipCardInner className="flip-card-inner">
            <FlipCardFront>
              <ServiceTitle>Data Analytics</ServiceTitle>
              <ServiceImage src={DataAnalyticsImage} alt="Data Analytics" />
              <ServiceDescription>
                <li>Advanced Insights</li>
                <li>Insightful Data</li>
                <li>Data-Driven Decisions</li>
              </ServiceDescription>
            </FlipCardFront>
            <FlipCardBack>
              <ServiceTitle>Data Analytics</ServiceTitle>
              <ServiceDescription>
                <li><strong>Advanced Insights:</strong> Transform your data into actionable insights with our advanced data analytics services.</li>
                <li><strong>Insightful Data:</strong> We harness cutting-edge tools and techniques to analyze your data, uncover trends, and provide strategic recommendations.</li>
                <li><strong>Data-Driven Decisions:</strong> Our data-driven approach helps you make informed decisions and achieve your business objectives.</li>
              </ServiceDescription>
              <Button onClick={onContactClick}>Contact Us</Button>
            </FlipCardBack>
          </FlipCardInner>
        </ServiceCard>
        <ServiceCard>
          <FlipCardInner className="flip-card-inner">
            <FlipCardFront>
              <ServiceTitle>Business Support</ServiceTitle>
              <ServiceImage src={BusinessSupportImage} alt="Business Support" />
              <ServiceDescription>
                <li>Operational Efficiency</li>
                <li>Administrative Assistance</li>
                <li>Process Automation</li>
              </ServiceDescription>
            </FlipCardFront>
            <FlipCardBack>
              <ServiceTitle>Business Support</ServiceTitle>
              <ServiceDescription>
                <li><strong>Operational Efficiency:</strong> Optimize your business operations with our comprehensive support services.</li>
                <li><strong>Administrative Assistance:</strong> We provide the essential support you need to run your business smoothly.</li>
                <li><strong>Process Automation:</strong> Our experienced team helps you streamline processes, 
                improve productivity, and focus on what you do best—growing your business.</li>
              </ServiceDescription>
              <Button onClick={onContactClick}>Contact Us</Button>
            </FlipCardBack>
          </FlipCardInner>
        </ServiceCard>
      </ServicesGrid>
    </ServicesContainer>
  );
};

export default AllServices;

